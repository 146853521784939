import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Userfront from '@userfront/toolkit/react';
import api from './../api/axios'; 
import './LogChart.css'; 
import { faCog, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'; 

const MixedChart = () => {
  const { t } = useTranslation(); 
  const [interval, setInterval] = useState('7days');
  const [logs, setLogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedActions, setSelectedActions] = useState(['viewProfile', 'sendConnectionRequest', 'messageReceived', 'messageSent', 'requestAccepted']);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({ interval: false, user: false, action: false });

  const actionLabels = {
    viewProfile: t('home.viewProfile'), 
    sendConnectionRequest: t('home.sendConnectionRequest'),
    messageReceived: t('home.messageReceived'),
    messageSent: t('home.messageSent'),
    requestAccepted: t('home.requestAccepted'),
  };

  const currentUser = Userfront.user.email;

  useEffect(() => {
    fetchOrganizationUsers();
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [interval, selectedUser]);

  useEffect(() => {
    filterLogsByActionType();
  }, [selectedActions, logs]);

  useEffect(() => {
    if (logs.length === 0) {
      fetchLogs(); // Fetch logs if not already fetched
    } else {
      setFilteredLogs(logs);
    }
  }, [logs]);

  const fetchOrganizationUsers = async () => {
    try {
      const response = await api.get(`/organization-users?email=${currentUser}`);
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
  };

  const fetchLogs = async () => {
    try {
      const userToFetch = selectedUser || currentUser;
      const response = await api.get(`/logs/${userToFetch}`);
      setLogs(response.data);
      setFilteredLogs(response.data);
      const xAxisCategories = getXAxisCategories(response.data);
      setCategories(xAxisCategories);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogs([]);
      setCategories([]);
    }
  };

  const filterLogsByActionType = () => {
    if (selectedActions.length === 0) {
      setFilteredLogs([]);
    } else {
      const filtered = logs.filter(log => selectedActions.includes(log.action));
      setFilteredLogs(filtered);
    }
  };

  const getXAxisCategories = (logsData) => {
    if (!logsData || logsData.length === 0) {
      return [];
    }
  
    let days = 7; // Default interval
  
    if (interval === '30days') {
      days = 30;
    } else if (interval === '90days') {
      days = 90;
    }
  
    const categories = [];
    const currentDate = new Date();
    
    // Clear time part from the current date
    currentDate.setHours(0, 0, 0, 0); 
  
    for (let i = 0; i < days; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
  
      // Format the date to '1 Oct', etc.
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      categories.push({ x: date.getTime(), formattedDate });
    }
  
    return categories.reverse(); // Keep in chronological order (oldest to newest)
  };
  


  const generateSeriesData = () => {
    if (!filteredLogs || filteredLogs.length === 0 || !categories || categories.length === 0) {
      return []; // Return empty if no data
    }
  
    const actionTypes = selectedActions;
  
    const seriesData = actionTypes.map(action => {
      const data = categories.map(category => {
        const logCount = filteredLogs.filter(log => log.action === action && isSameDay(new Date(log.timestamp), new Date(category.x))).length;
        return { x: category.x, y: logCount };
      });
  
      return { name: actionLabels[action], data };
    });
  
    return seriesData.filter(series => series.data.some(point => point.y > 0));
  };
  
  const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  };

  const handleActionChange = (action) => {
    if (selectedActions.includes(action)) {
      setSelectedActions(selectedActions.filter(a => a !== action));
    } else {
      setSelectedActions([...selectedActions, action]);
    }
  };

  const handleUserChange = (user) => {
    setSelectedUser(user);
    setDropdownStates(prevStates => ({ ...prevStates, user: false }));
  };

  const handleIntervalChange = (period) => {
    setInterval(period);
    setDropdownStates(prevStates => ({ ...prevStates, interval: false }));
  };

  const handleDropdownToggle = (dropdown) => {
    setDropdownStates(prevStates => ({
      ...prevStates,
      [dropdown]: !prevStates[dropdown],
    }));
  };

  const options = {
    chart: {
      id: 'mixed-chart',
      stacked: true,
      type: 'bar',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
      tickAmount: categories.length, // Match tick amount to the number of categories
      labels: {
        format: 'dd MMM',
        rotate: -45,
      },
      tickPlacement: 'on', // Ensures bars are centered on the ticks
      position: 'bottom',
      categories: categories.map(category => category.x), // Ensure categories are mapped correctly to date timestamps
      axisTicks: {
        show: true,
        align: 'center', // Center the ticks
      },
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    stroke: {
      width: [1],
    },
    plotOptions: {
      bar: {
        columnWidth: '100%', // Adjust width for better alignment
        barHeight: '100%',
        horizontal: false,
        borderRadius: 0,
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val ? val.toFixed(0) : '';
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
    },
    legend: {
      position: 'top',
      offsetY: 15,
    },
  };
  
  console.log("categories: " + categories)
  

  return (
    <div className="mixed-chart">
      <div className="chart-header header-container header-container-home">
        <h2>{t('home.timeline')}</h2> 
        <FontAwesomeIcon 
          icon={faCog} 
          className="cog-icon-button" 
          onClick={() => setShowFilters(!showFilters)} 
        />
      </div>
      {showFilters && (
        <div className="controls">
          <span className='graph-filter-span font-medium'>{t('home.filters')}:</span>
          {/* Interval Dropdown */}
          <div className="dropdown interval-selector">
            <button className="dropdown-button btn-chart-filter" onClick={() => handleDropdownToggle('interval')}>
              {t('home.period')} <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className={`dropdown-content ${dropdownStates.interval ? 'show' : ''}`}>
              <button onClick={() => handleIntervalChange('7days')}>{t('home.last7Days')}</button>
              <button onClick={() => handleIntervalChange('30days')}>{t('home.last30Days')}</button>
              <button onClick={() => handleIntervalChange('90days')}>{t('home.last90Days')}</button>
            </div>
          </div>

          {/* User Selector Dropdown */}
          <div className="dropdown user-selector">
            <button className="dropdown-button btn-chart-filter" onClick={() => handleDropdownToggle('user')}>
              {t('home.members')} <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className={`dropdown-content ${dropdownStates.user ? 'show' : ''}`}>
              <button onClick={() => handleUserChange('')}>{t('home.allUsers')}</button>
              {users.map(user => (
                <button key={user._id} onClick={() => handleUserChange(user.email)}>
                  {user.email}
                </button>
              ))}
            </div>
          </div>

          {/* Actions Dropdown */}
          <div className="dropdown action-selector">
            <button className="dropdown-button btn-chart-filter" onClick={() => handleDropdownToggle('action')}>
              {t('home.actionType')} <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className={`dropdown-content ${dropdownStates.action ? 'show' : ''}`}>
              {Object.keys(actionLabels).map(action => (
                <div key={action}>
                  <input 
                    type="checkbox" 
                    checked={selectedActions.includes(action)} 
                    onChange={() => handleActionChange(action)} 
                  />
                  <label>{actionLabels[action]}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <ReactApexChart
        options={options}
        series={generateSeriesData()} 
        type="bar"
        height={350}
      />
    </div>
  );
};

export default MixedChart;
