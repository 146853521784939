import React, { useState, useEffect } from 'react';
import './Campaigns.css';
import Sidebar from './../../components/Sidebar';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import NavBar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import Userfront from "@userfront/toolkit/react";
import api from './../../api/axios';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook

function Campaigns() {
  const collapsed = usePersistentCollapsedState();
  const { t, i18n } = useTranslation();  // Initialize translation

  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [showStartNewCampaignModal, setShowStartNewCampaignModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState({ title: '', description: '', status: 'Not Started' });
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [userLanguage, setUserLanguage] = useState('en'); // State for user language

  const createNewCampaign = async () => {
    try {
      const response = await api.post('/campaigns', {
        user: Userfront.user.email,
        title: newCampaign.title,
        description: newCampaign.description,
      });
      setCampaigns([...campaigns, response.data]);
      setFilteredCampaigns([...campaigns, response.data]);
      setShowModal(false);
      window.location.replace(`/campaigns/${response.data.result.insertedId}`);
    } catch (error) {
      console.error('Error creating campaign:', error.response.data);
    }
  };

  const deleteCampaign = async (campaignId) => {
    try {
      await api.delete(`/campaigns/${campaignId}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    } finally {
      setShowDeleteConfirmationModal(false);
      setCampaignToDelete(null);
    }
  };

  const fetchCampaigns = async () => {
    try {
        const userEmail = Userfront.user.email; // Get the email from Userfront

        // First, fetch the user details to get the organization ID
        const userResponse = await api.get(`/user?email=${userEmail}`);
        
        // Ensure user data is available
        if (userResponse.status === 200 && userResponse.data.user) {
            const organizationId = userResponse.data.user.organizationId; // Assuming organizationId is part of user data
            
            // Now fetch campaigns for the organization
            const campaignsResponse = await api.get(`/organization/${organizationId}/campaigns`);
            
            // Set campaigns and filtered campaigns
            setCampaigns(campaignsResponse.data);
            setFilteredCampaigns(campaignsResponse.data); // Assuming you have a filtered state
        } else {
            console.error('User not found or organization ID is missing.');
        }
    } catch (error) {
        console.error('Error fetching campaigns:', error);
    }
  };

  

  // Fetch user language setting from the database
  const fetchUserLanguage = async () => {
    try {
      const response = await api.get(`/user?email=${Userfront.user.email}`);
      setUserLanguage(response.data.language);
      i18n.changeLanguage(response.data.language); // Set language in i18next
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    fetchUserLanguage();
  }, []);

  const handleDeleteClick = (campaignId) => {
    setCampaignToDelete(campaignId);
    setShowDeleteConfirmationModal(true);
  };

  useEffect(() => {
    const filtered = campaigns.filter((campaign) =>
      campaign.title && campaign.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredByStatus = filtered.filter((campaign) => {
      if (selectedStatusFilter !== 'all' && campaign.status !== selectedStatusFilter) {
        return false;
      }
      return true;
    });

    setFilteredCampaigns(filteredByStatus);
  }, [searchTerm, selectedStatusFilter, campaigns]);

  const handleNewCampaignSubmit = () => {
    createNewCampaign();
  };

  const getStatusLabelClass = (status) => {
    switch (status) {
      case 'Concept':
        return 'status-label-concept';
      case 'Active':
        return 'status-label-active';
      case 'Paused':
        return 'status-label-paused';
      case 'Completed':
        return 'status-label-completed';
      case 'Stopped':
        return 'status-label-stopped';
      case 'Archived':
        return 'status-label-archived';
      default:
        return '';
    }
  };

  const renderTooltip = (user, props) => (
    <Tooltip id="button-tooltip" className='custom-tooltip' {...props}>
      {user}
    </Tooltip>
  );

  return (
    <div className={`dashboard`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="campaigns col-md-12">
            <div className='activities-widget'>
              <h2>{t('campaigns.title')}</h2>
              <div className='row'>
                <div className="col-md-4">
                  <input
                    className='form-control'
                    type="text"
                    placeholder={t('campaigns.searchPlaceholder')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <div className="status-filter">
                    <select
                      className='form-control'
                      value={selectedStatusFilter}
                      onChange={(e) => setSelectedStatusFilter(e.target.value)}
                    >
                      <option hidden value="all">{t('campaigns.filter')}</option>
                      <option value="all">{t('campaigns.all')}</option>
                      <option value="Active">{t('campaigns.active')}</option>
                      <option value="Paused">{t('campaigns.paused')}</option>
                      <option value="Concept">{t('campaigns.concept')}</option>
                      <option value="Stopped">{t('campaigns.stopped')}</option>
                      <option value="Completed">{t('campaigns.completed')}</option>
                      <option value="Archived">{t('campaigns.archived')}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="create-button">
                    <Button className='startNewCampaignButton' variant="primary" onClick={() => setShowStartNewCampaignModal(true)}>
                      {t('campaigns.startNew')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className='table-responsive campaigns-table'>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>{t('campaigns.title')}</th>
                      <th style={{ width: '15%' }}>{t('campaigns.progress')}</th>
                      <th style={{ width: '15%' }}>{t('campaigns.status')}</th>
                      <th style={{ width: '15%' }}>{t('campaigns.createdBy')}</th>
                      <th style={{ width: '15%' }}>{t('campaigns.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCampaigns.map((campaign) => (
                      <tr key={campaign._id}>
                        <td>{campaign.title}</td>
                        <td></td>
                        <td>
                          <div className={`status-label-container ${getStatusLabelClass(campaign.status)}`}>
                            {campaign.status}
                          </div>
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip(campaign.user)}
                          >
                            <div className="user-initial">{campaign.user.charAt(0).toUpperCase()}</div>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle variant="link" bsPrefix="p-0">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item as={Link} to={`/campaigns/${campaign._id}`}>
                                <FontAwesomeIcon icon={faEdit} /> {t('campaigns.edit')}
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDeleteClick(campaign._id)}>
                                <FontAwesomeIcon icon={faTrash} /> {t('campaigns.delete')}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Start New Campaign Modal */}

        <Modal show={showStartNewCampaignModal} onHide={() => setShowStartNewCampaignModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('campaigns.startNew')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formNewCampaignTitle">
              <Form.Label>{t('campaigns.modalTitle')}</Form.Label>
              <Form.Control
                type="text"
                value={newCampaign.title}
                onChange={(e) => setNewCampaign({ ...newCampaign, title: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formNewCampaignDescription">
              <Form.Label>{t('campaigns.description')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newCampaign.description}
                onChange={(e) => setNewCampaign({ ...newCampaign, description: e.target.value })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowStartNewCampaignModal(false)}>
              {t('campaigns.cancel')}
            </Button>
            <Button variant="primary" onClick={handleNewCampaignSubmit}>
              {t('campaigns.save')}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('campaigns.confirmDelete')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('campaigns.deleteConfirmation')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>
              {t('campaigns.cancel')}
            </Button>
            <Button variant="danger" onClick={() => deleteCampaign(campaignToDelete)}>
              {t('campaigns.delete')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  );
}

export default Campaigns;
