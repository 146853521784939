import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Settings.css';
import Sidebar from './../../components/Sidebar';
import NavBar from '../../components/Navbar';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import countries from '../../components/Countries';
import countriesLocation from '../../components/CountriesLocation';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import Userfront from '@userfront/toolkit/react';
import api from './../../api/axios';
import { FaPlusCircle } from 'react-icons/fa'; // Font Awesome icon
import { Modal, Button, Form } from 'react-bootstrap';


function Settings() {
  const collapsed = usePersistentCollapsedState();
  const user = Userfront.user.email;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [connectionLimit, setConnectionLimit] = useState(0);
  const [profileViewLimit, setProfileViewLimit] = useState(0);
  const [messagesLimit, setMessagesLimit] = useState(0);
  const [profileImage, setProfileImage] = useState('');
  const [country, setCountry] = useState('');
  const [currentUser, setCurrentUser] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [showLocationModal, setShowLocationModal] = useState(false);


  // Sorting countries alphabetically by name
const sortedCountriesLocation = countriesLocation.sort((a, b) => a.name.localeCompare(b.name));


  const location = useLocation();
  
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState('');

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const [invoicingDetails, setInvoicingDetails] = useState({
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    country: '',
    state: '',
    taxId: '',
    taxIdType: 'eu_vat',
  });
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('general');
  const userInUserfront = Userfront.user;


  useEffect(() => {
    // Get query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    
    if (tab) {
      setActiveTab(tab); // Set the active tab based on the query param
    }
  }, [location]);

  const getCurrentTimezoneOffset = () => {
    const date = new Date();
    const janOffset = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const julOffset = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return janOffset === julOffset ? janOffset : (janOffset > julOffset ? -1 : 1);
  };
  
  // Declare the function normally
function determineEffectiveTimezone() {
  const offset = getCurrentTimezoneOffset();
  if (offset === -120) { // UTC+2
    return 'UTC+2';
  } else if (offset === -60) { // UTC+1
    return 'UTC+1';
  }
  return 'UTC'; // Default case
}

// Then use it in your state initialization
const [timezone, setTimezone] = useState(determineEffectiveTimezone());


const timezones = [
  { value: 'UTC-12', label: 'UTC-12', cities: ['Baker Island'] },
  { value: 'UTC-11', label: 'UTC-11', cities: ['American Samoa'] },
  { value: 'UTC-10', label: 'UTC-10', cities: ['Hawaii', 'Honolulu'] },
  { value: 'UTC-9', label: 'UTC-9', cities: ['Alaska'] },
  { value: 'UTC-8', label: 'UTC-8', cities: ['Los Angeles', 'San Francisco'] },
  { value: 'UTC-7', label: 'UTC-7', cities: ['Denver', 'Phoenix'] },
  { value: 'UTC-6', label: 'UTC-6', cities: ['Chicago', 'Houston'] },
  { value: 'UTC-5', label: 'UTC-5', cities: ['New York', 'Toronto'] },
  { value: 'UTC-4', label: 'UTC-4', cities: ['Caracas', 'Santiago'] },
  { value: 'UTC-3', label: 'UTC-3', cities: ['Brasília', 'Buenos Aires'] },
  { value: 'UTC-2', label: 'UTC-2', cities: ['South Georgia'] },
  { value: 'UTC-1', label: 'UTC-1', cities: ['Azores'] },
  { value: 'UTC', label: 'UTC', cities: ['London', 'Reykjavik'] },
  { value: 'UTC+1', label: 'UTC+1', cities: ['Berlin', 'Paris', 'Amsterdam'] },
  { value: 'UTC+2', label: 'UTC+2', cities: ['Amsterdam (during CEST)', 'Athens', 'Cairo'] },
  { value: 'UTC+3', label: 'UTC+3', cities: ['Moscow', 'Nairobi'] },
  { value: 'UTC+4', label: 'UTC+4', cities: ['Dubai', 'Baku'] },
  { value: 'UTC+5:30', label: 'UTC+5:30', cities: ['New Delhi', 'Mumbai'] },
  { value: 'UTC+8', label: 'UTC+8', cities: ['Beijing', 'Shanghai'] },
  { value: 'UTC+9', label: 'UTC+9', cities: ['Tokyo', 'Osaka'] },
  // Add more timezones as needed
];


useEffect(() => {
  const fetchSettings = async () => {
    try {
      const userResponse = await api.get(`/user?email=${user}`);
      const currentUser = userResponse.data.user;

      // Set other user details...
      setCurrentUser(currentUser || '');
      setCountry(currentUser.country || '');
      setTimezone(currentUser.timezone || '');
      setProfileImage(currentUser.profileImageUrl || '');
      setConnectionLimit(currentUser.connectionLimit || 100);
      setProfileViewLimit(currentUser.profileViewLimit || '');
      setMessagesLimit(currentUser.messagesLimit || '');

      // Set language from API response only if it's not already set
      const userLanguage = currentUser.language || 'en';
      if (!language) {
      setLanguage(userLanguage);
      i18n.changeLanguage(userLanguage);
    }
      
      const orgResponse = await api.get(`/organizations/${currentUser.organizationId}`);
      setInvoicingDetails(orgResponse.data.invoicingDetails || {});
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError(`${t('errors.fetchSettings')} ${error.response?.data?.error || t('errors.unknown')}`);
    }
  };

  fetchSettings();
}, [email, t, i18n]); // Removed language from dependencies




  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', user); // Include email in the request body
  
    try {
      // Upload image to backend (which will forward it to Cloudinary and update DB)
      const response = await api.post('/upload-profile-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      
      const imageUrl = response.data.imageUrl;
      setProfileImage(imageUrl);  // Set the profile image URL in the frontend
      setError('');  // Clear error
    } catch (error) {
      console.error('Error uploading image:', error);
      setError(t('errors.uploadImage'));
    }
  };
  
  const handleSaveGeneral = async () => {
    try {
      await api.put('/user/general', { email: user, profileImageUrl: profileImage, language });
      setError('');
    } catch (error) {
      console.error('Error saving general settings:', error);
      setError(t('errors.saveSettings'));
    }
  };



  const handleSaveLocationClick = () => {
    // Open the modal to inform the user
    setShowModal(true);
  };

  
  const handleSaveLocation = async () => {
    try {
      await api.put('/user/location', { email: user, country, timezone });
      setError('');
      setShowModal(false); // Close the modal after successful save
    } catch (error) {
      console.error('Error saving location settings:', error);
      setError(t('errors.saveSettings'));
    }
  };
  
  


  const handleSaveCookie = async () => {
    try {
      if (!currentUser.location) {
        // If the user's location is not set, show the modal
        setShowLocationModal(true);
        return;
      }
  
      // Proceed with saving the LinkedIn cookie
      const response = await api.post('/save-cookie', {
        userEmail: user,
        email: email,
        password: password,
      });
      console.log(response.data);
  
      // Clear the input fields after saving the cookie
      setPassword('');
      setError('');
    } catch (error) {
      console.error('Error saving cookie:', error.response?.data);
      setError(t('errors.saveCookie'));
    }
  };

  // Function to redirect to the location tab
  const redirectToLocationTab = () => {
    setActiveTab('location');
    setShowLocationModal(false);  // Close the modal
  };


  const handleSaveLimits = async () => {
    try {
      await api.put('/users/limits', { email: user, connectionLimit, profileViewLimit, messagesLimit });
      setError('');
    } catch (error) {
      setError(t('errors.saveLimits'));
    }
  };

  const handleOpenLinkedInPopup = () => {
    // Your existing logic for opening LinkedIn login popup
  };

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className='container'>
        <NavBar />
        <div className="row">
          <div className='col-md-12'>
            <div className='activities-widget'>
              <h2>{t('settings.pageHeader')}</h2>
              <div className="tabs">
                <button className={activeTab === 'general' ? 'tab active' : 'tab'} onClick={() => setActiveTab('general')}>{t('settings.generalSettings')}</button>
                <button className={activeTab === 'location' ? 'tab active' : 'tab'} onClick={() => setActiveTab('location')}>{t('settings.locationSettings')}</button>
                <button className={activeTab === 'saveCookie' ? 'tab active' : 'tab'} onClick={() => setActiveTab('saveCookie')}>{t('settings.connectLinkedInAccount')}</button>
                <button className={activeTab === 'saveLimits' ? 'tab active' : 'tab'} onClick={() => setActiveTab('saveLimits')}>{t('settings.manageDailyLimits')}</button>
                <hr className='tabs-hr'></hr>
              </div>
              <div className='col-md-12'>
              <div className="Settings">



                {activeTab === 'general' && (
                  <div>
                    <h3>{t('settings.generalSettings')}</h3>

                    <div className="form-group">
                      <label htmlFor="email">{t('settings.email')}</label>
                      <input type="email" id="email" value={user} disabled className="form-control" />
                      <a className="btn btn-primary" href='/reset-password'>{t('settings.resetPassword')}</a>
                    </div>

                    <div className="form-group">
                        <label htmlFor="profileImage">{t('settings.profileImage')}</label>
                        <div className="profile-image-container">
                          <img
                            src={profileImage || '/default-avatar.png'}
                            alt="Profile"
                            className="profile-image"
                          />
                          <div className="image-overlay">
                            <FaPlusCircle className="upload-icon" />
                            <input
                              type="file"
                              className="file-input"
                              onChange={handleProfileImageChange}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                      <hr/>
                      <div className="form-group">
                        <label htmlFor="language">{t('settings.language')}</label>
                        <select
                          id="language"
                          value={language}
                          onChange={handleLanguageChange}
                        >
                          <option value="en">{t('languages.english')}</option>
                          <option value="nl">{t('languages.dutch')}</option>
                        </select>
                      </div>
                    <button className="btn btn-primary" onClick={handleSaveGeneral}>{t('actions.save')}</button>
                  </div>
                )}

                {activeTab === 'location' && (
                  <div>
                    <h3>{t('settings.locationSettings')}</h3>         
                    <div className="form-group">
                      <label htmlFor="country">{t('settings.country')}</label>

                      {!currentUser.proxyServer && (
                        <select id="country" className="form-control" value={country} onChange={(e) => setCountry(e.target.value)}>
                          {sortedCountriesLocation.map(country => (
                            <option key={country.alpha3} value={country.id}>{country.name}</option>
                          ))}
                        </select>

                      )}


                      {currentUser.proxyServer && (
                        <select id="country" className="form-control" value={country} onChange={(e) => setCountry(e.target.value)} disabled>
                          {sortedCountriesLocation.map(country => (
                            <option key={country.alpha3} value={country.id}>{country.name}</option>
                          ))}
                        </select>

                      )}

                    </div>
                    <div className="form-group">
                      <label htmlFor="timezone">{t('settings.timezone')}</label>
                      <select id="timezone" className="form-control" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                        {timezones.map(zone => (
                          <option key={zone.value} value={zone.value}>
                            {zone.label} ({zone.cities.join(', ')})
                          </option>
                        ))}
                      </select>
                    </div>
      
                    <button className="btn btn-primary" onClick={handleSaveLocationClick}>
                      {t('actions.save')}
                    </button>

                  </div>
                )}

                {activeTab === 'saveCookie' && (
                  <div>
                    <h3>{t('settings.connectLinkedInAccount')}</h3>
                    {error && <div className="error-message">{error}</div>}
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder={t('settings.email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      
                    />
                    <input
                      className="form-control mb-3"
                      type="password"
                      placeholder={t('settings.password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-3">
                      <button className="btn btn-primary me-md-2" onClick={handleSaveCookie}>
                        {t('actions.saveLinkedInCookie')}
                      </button>

                      {/** 
                      <button className="btn btn-secondary" onClick={handleOpenLinkedInPopup}>
                        {t('actions.loginWithLinkedIn')}
                      </button>

                      */}

                    </div>
                  </div>
                )}

                {activeTab === 'saveLimits' && (
                  <div className="limits-form">
                    <h2>{t('settings.manageDailyLimits')}</h2>
                    <div className="form-group">
                      <label htmlFor="connectionLimit">{t('settings.connectionRequestLimit')}</label>
                      <input
                        className="form-control"
                        type="range"
                        id="connectionLimit"
                        min="0"
                        max="100"
                        step="10"
                        value={connectionLimit}
                        onChange={(e) => setConnectionLimit(e.target.value)}
                      />
                      <div className="slider-value">{connectionLimit}</div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="profileViewLimit">{t('settings.profileViewLimit')}</label>
                      <input
                        className="form-control"
                        type="range"
                        id="profileViewLimit"
                        min="0"
                        max="50"
                        step="10"
                        value={profileViewLimit}
                        onChange={(e) => setProfileViewLimit(e.target.value)}
                      />
                      <div className="slider-value">{profileViewLimit}</div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="messagesLimit">{t('settings.messagesLimit')}</label>
                      <input
                        className="form-control"
                        type="range"
                        id="messagesLimit"
                        min="0"
                        max="40"
                        step="10"
                        value={messagesLimit}
                        onChange={(e) => setMessagesLimit(e.target.value)}
                      />
                      <div className="slider-value">{messagesLimit}</div>
                    </div>

                    <button className="btn btn-primary" onClick={handleSaveLimits}>
                      {t('actions.saveLimits')}
                    </button>
                  </div>
                )}
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>



      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('settings.locationWarningTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('settings.locationWarningMessage')}</Form.Label>
            <Form.Text className="text-muted">
              {t('settings.locationSupportMessage')}
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('actions.cancel')}
          </Button>
          <Button variant="primary" onClick={handleSaveLocation}>
            {t('actions.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
      )}


    {showLocationModal && (
    <Modal show={showLocationModal} onHide={() => setShowLocationModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t('settings.locationRequiredTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('settings.locationRequiredMessage')}</p>
        <p>{t('settings.locationSupportMessage')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowLocationModal(false)}>
          {t('actions.cancel')}
        </Button>
        <Button variant="primary" onClick={redirectToLocationTab}>
          {t('actions.goToLocationSettings')}
        </Button>
      </Modal.Footer>
    </Modal>
    )}

    </div>
  );
}

export default Settings;
