import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Messages.css'; // Style file for component
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import { FaEnvelope } from 'react-icons/fa'; // Import Font Awesome icon for envelope
import api from './../../api/axios'; // Import your axios instance
import Userfront from "@userfront/toolkit/react";
import { useTranslation } from 'react-i18next';  // Import useTranslation hook

const Messages = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [userMessage, setUserMessage] = useState(''); // State for user's message input
  const [aiResponse, setAiResponse] = useState(''); // State for AI generated response

  const { t, i18n } = useTranslation();  // Initialize translation
  const [userLanguage, setUserLanguage] = useState('en'); // State for user language

  useEffect(() => {
    fetchLeads();
    fetchUserLanguage();
  }, []);

  // Fetch user language setting from the database
  const fetchUserLanguage = async () => {
    try {
      const response = await api.get(`/user?email=${Userfront.user.email}`);
      setUserLanguage(response.data.language);
      i18n.changeLanguage(response.data.language); // Set language in i18next
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const fetchLeads = async () => {
    try {
      const response = await api.get('/messages/leads');
      setLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const fetchMessages = async (leadName) => {
    try {
      const response = await api.get(`/messages/messages/${leadName}`);
      setMessages(response.data);
      setSelectedLead(leadName);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const markMessagesAsRead = async (leadName) => {
    try {
      const response = await api.post(`/messages/mark-read/${leadName}`);
      console.log('Messages marked as read:', response.data);
      fetchMessages(leadName); // Refetch messages to update read status visually
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const countUnreadMessages = (leadName) => {
    if (selectedLead === leadName) {
      return 0; // No unread messages in the current selected lead
    }
    const lead = leads.find((lead) => lead.leadName === leadName);
    if (!lead) {
      return 0; // Lead not found
    }
    return lead.messages.filter((message) => message.status === 'unread').length;
  };

  // Handle user message submission
  const handleSendMessage = async () => {
    try {
      const response = await api.post('/messages/send', {
        leadName: selectedLead,
        message: userMessage,
      });
      console.log('Message sent successfully:', response.data);
      fetchMessages(selectedLead); // Refetch messages after sending
      setUserMessage(''); // Clear the textarea after sending
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Handle AI response generation
  const handleGenerateAiResponse = async () => {
    try {
      const response = await api.post('/messages/generate-response', {
        leadName: selectedLead,
        conversation: messages, // Send conversation history for context
      });
      setAiResponse(response.data.aiResponse); // Set the AI response in state
    } catch (error) {
      console.error('Error generating AI response:', error);
    }
  };

  // Filter leads based on the search term
  const filteredLeads = leads.filter((lead) =>
    lead.leadName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="col-md-12">
            <div className="message-widget">
              <div className="row">
                <div className="col-md-3">
                  <h2>{t('messages.inboxTitle')}</h2>
                  {/* Search Input */}
                  <input
                    type="text"
                    placeholder={t('messages.searchPlaceholder')}
                    className="lead-search-input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {/* Lead List */}
                  <ul className="lead-list">
                    {filteredLeads.map((lead, index) => (
                      <li key={index} className="lead-item" onClick={() => fetchMessages(lead.leadName)}>
                        {lead.profileImageUrl ? (
                          <img
                            src={lead.profileImageUrl}
                            alt={t('messages.leadAvatarAlt')}
                            className="lead-avatar"
                          />
                        ) : (
                          <div className="lead-initial">
                            {lead.leadName.charAt(0).toUpperCase()}
                          </div>
                        )}
                        <span className="lead-name">{lead.leadName}</span>
                        {countUnreadMessages(lead.leadName) > 0 && (
                          <span className="unread-badge">
                            {countUnreadMessages(lead.leadName)}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-9">
                  
                  {selectedLead && (
                    <div>
                
                      {messages.map((message, index) => (
                        <div
                          key={index}
                          className={`message ${message.status === 'read' ? 'read' : 'unread'}`}
                          onClick={() => markMessagesAsRead(selectedLead)}
                        >
                          <div className="sender">{message.sender}</div>
                          <div className="text">{message.text}</div>
                        </div>
                      ))}
                      <textarea
                        className="message-input"
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                        placeholder={t('messages.writeMessagePlaceholder')}
                      />
                      <div className="message-buttons">
                        <button onClick={handleSendMessage} className="send-message-button">
                          {t('messages.sendMessageButton')}
                        </button>
                        <button onClick={handleGenerateAiResponse} className="generate-response-button">
                          {t('messages.generateAiResponseButton')}
                        </button>
                      </div>
                      {aiResponse && (
                        <div className="ai-response">
                          <h3>{t('messages.aiResponseTitle')}</h3>
                          <p>{aiResponse}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
