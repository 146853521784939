import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Userfront from '@userfront/toolkit/react'; // Ensure this is imported correctly
import api from './../../api/axios';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginMessage, setLoginMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uuid = params.get('uuid');
    const token = params.get('token');

    if (uuid && token) {
      // Log in with link
      Userfront.login({
        method: 'link',
        token: token,
        uuid: uuid,
      })
        .then(() => {
          setLoginMessage('Email confirmed and logged in successfully!');
          navigate('/dashboard'); // Redirect to dashboard after login
        })
        .catch((error) => {
          console.error('Error confirming email:', error);
          setLoginMessage('There was an error confirming your email. Please try again later.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (location.state?.from?.pathname === '/confirmation') {
      setLoginMessage('Please confirm your email before logging in.');
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginMessage('');
  
    try {
      // Authenticate with Userfront
      const userfrontResponse = await Userfront.login({
        method: 'password',
        email,
        password,
      });
  
      // On successful login, send the email to your backend to create a session
      await api.post('/api/login-session', { email }, { withCredentials: true });
  
      // On successful session creation, redirect to the dashboard
      setLoginMessage('Login successful!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error during login:', error);
      setLoginMessage('An error occurred. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className='container-login'>
      <div className="login">
        <img className='logo' src="/logo.png" alt="LeadgenFlow Logo" />
        <h1 className="login-title">Login</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <label className="form-label">
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-input"
            />
          </label>
          <label className="form-label">
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-input"
            />
          </label>
          <button type="submit" disabled={loading} className="form-button">
            {loading ? 'Logging in...' : 'Login'}
          </button>
          {loginMessage && <div className="login-message">{loginMessage}</div>}
        </form>
        <div className="login-links">
          <a href="/reset-password" className="login-link">Forgot your password?</a>
          <a href="/register" className="login-link">Don't have an account? Register</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
