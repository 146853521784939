import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './../../components/CheckoutForm';
import Userfront from '@userfront/toolkit/react';
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import './Subscription.css';
import api from './../../api/axios';
import { useLocation } from 'react-router-dom';
import countries from './../../components/Countries'; // Import countries list

import { useTranslation } from 'react-i18next';  // Import useTranslation hook

const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY;

if (!stripeApiKey || typeof stripeApiKey !== 'string') {
  throw new Error('Missing or invalid Stripe API key. Please check your environment variables.');
}

const stripePromise = loadStripe(stripeApiKey);

const currencyCodeToSymbol = (currency) => {
  const currencyMap = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    AUD: 'A$',
    CAD: 'C$',
    CHF: 'CHF',
    CNY: '¥',
    SEK: 'kr',
    NZD: 'NZ$',
  };

  return currencyMap[currency] || currency;
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const SubscriptionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [message, setMessage] = useState('');
  const [showBasicPlans, setShowBasicPlans] = useState(true);
  const [activeTab, setActiveTab] = useState('choosePlan');
  const [invoicingDetails, setInvoicingDetails] = useState({
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    country: '',
    state: '',
    taxId: '',
    taxIdType: 'eu_vat',
  });
  const [organizationId, setOrganizationId] = useState('');
  const [error, setError] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState('');

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const adminEmail = Userfront.user.email;

  const { t, i18n } = useTranslation();  // Initialize translation
  const [userLanguage, setUserLanguage] = useState('en'); // State for user language

  // Fetch user language setting from the database
  const fetchUserLanguage = async () => {
    try {
      const response = await api.get(`/user?email=${Userfront.user.email}`);
      setUserLanguage(response.data.language);
      i18n.changeLanguage(response.data.language); // Set language in i18next
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };


  useEffect(() => {
    fetchUserLanguage();
    
    
    const fetchSettings = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          return;
        }

        const userResponse = await api.get(`/user?email=${userEmail}`);
        const currentUser = userResponse.data.user;
        setOrganizationId(currentUser.organizationId);

        const orgResponse = await api.get(`/organizations/${currentUser.organizationId}`);
        setInvoicingDetails(orgResponse.data.invoicingDetails || {});
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError('Error fetching settings: ' + error.response?.data?.error || 'Unknown error');
      }
    };

    fetchSettings();
  }, [adminEmail]);

  useEffect(() => {
    if (query.get('session_id')) {
      setMessage('Subscription was successful!');
    } else if (query.get('cancelled')) {
      setMessage('Subscription was cancelled.');
    }
  }, [location.search]);

  useEffect(() => {
    const fetchActiveSubscriptions = async () => {
      try {
        const response = await api.get(`/subscriptions?email=${adminEmail}`);
        const subscriptions = response.data.subscriptions;
        setActiveSubscriptions(subscriptions);

        const hasTeamSubscription = subscriptions.some(sub =>
          sub.planName.includes('Team')
        );
        setShowBasicPlans(!hasTeamSubscription);

        if (subscriptions.length > 0) {
          setActiveTab('activeSubscriptions');
        }
      } catch (error) {
        console.error('Error fetching active subscriptions:', error);
      }
    };

    const fetchInvoices = async () => {
      try {
        if (!adminEmail) {
          console.error('User email is not available.');
          return;
        }

        const userResponse = await api.get(`/user?email=${adminEmail}`);
        const currentUser = userResponse.data.user;

        if (!currentUser) {
          console.error('Current user not found.');
          return;
        }

        if (!currentUser.organizationId) {
          console.error('Organization ID is not available.');
          return;
        }

        const invoicesResponse = await api.get(`/invoices?organizationId=${currentUser.organizationId}`);
        const invoicesWithLocalDates = invoicesResponse.data.invoices.map(invoice => ({
          ...invoice,
          date: new Date(invoice.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        }));
        setInvoiceData(invoicesWithLocalDates);
        setOrganizationId(currentUser.organizationId);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    const fetchInvoicingDetails = async () => {
      try {
        if (!organizationId) return;

        const response = await api.get(`/organizations/${organizationId}`);
        setInvoicingDetails(response.data.invoicingDetails || {});
      } catch (error) {
        console.error('Error fetching invoicing details:', error);
        setError('Error fetching invoicing details: ' + error.response?.data?.error || 'Unknown error');
      }
    };

    const fetchPaymentMethods = async () => {
      try {
        const userResponse = await api.get(`/user?email=${adminEmail}`);
        const currentUser = userResponse.data.user;
    
        if (!currentUser || !currentUser.stripeCustomerId) {
          console.error('Stripe Customer ID is not available for this user.');
          return;
        }
    
        const response = await api.get(`/payment-methods?stripeCustomerId=${currentUser.stripeCustomerId}`);
        const methods = response.data.paymentMethods;
        setPaymentMethods(methods);
    
        // Retrieve the default payment method from the backend
        const activeMethodResponse = await api.get(`/active-payment-method?customerId=${currentUser.stripeCustomerId}`);
        const activeMethod = activeMethodResponse.data;
        setActivePaymentMethodId(activeMethod.id || '');
      } catch (error) {
        console.error('Error fetching payment methods:', error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchActiveSubscriptions(), fetchInvoices(), fetchPaymentMethods()]);
      await fetchInvoicingDetails();
      setLoading(false);
    };

    fetchData();
  }, [adminEmail, location.search, organizationId]);

  const handleSubscriptionSuccess = () => {
    setMessage('Subscription successful!');
  };

  const handleOpenCustomerPortalSubscription = async (subscriptionId) => {
    try {
      const userResponse = await api.get(`/user?email=${adminEmail}`);
      const user = userResponse.data.user;

      if (!user || !user.stripeCustomerId) {
        console.error('Stripe Customer ID is not available for this user.');
        return;
      }

      const response = await api.post('/create-customer-portal-session-subscription', {
        stripeCustomerId: user.stripeCustomerId,
        subscriptionId: subscriptionId,
      });

      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error opening Stripe Customer Portal:', error);
    }
  };

  const handleOpenCustomerPortal = async () => {
    try {
      const userResponse = await api.get(`/user?email=${adminEmail}`);
      const user = userResponse.data.user;

      if (!user || !user.stripeCustomerId) {
        console.error('Stripe Customer ID is not available for this user.');
        return;
      }

      const response = await api.post('/create-customer-portal-session', {
        stripeCustomerId: user.stripeCustomerId,
      });

      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error opening Stripe Customer Portal:', error);
    }
  };

  const handleContinuePlan = async () => {
    try {
      const userResponse = await api.get(`/user?email=${adminEmail}`);
      const user = userResponse.data.user;

      if (!user || !user.stripeCustomerId) {
        console.error('Stripe Customer ID is not available for this user.');
        return;
      }

      const response = await api.post('/create-customer-portal-session', {
        stripeCustomerId: user.stripeCustomerId,
      });

      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.error('Error opening Stripe Customer Portal:', error);
    }
  };

  const handleInvoicingChange = (e) => {
    const { name, value } = e.target;
    setInvoicingDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSaveInvoicingDetails = async () => {
    try {
      if (invoicingDetails.taxId && !invoicingDetails.taxIdType) {
        setError('Tax ID Type is required when Tax ID is provided');
        return;
      }

      const response = await api.put('/update-invoicing-details', {
        email: adminEmail,
        organizationId: organizationId,
        invoicingDetails,
      });

      console.log('Invoicing details saved:', response.data);
    } catch (error) {
      console.error('Error saving invoicing details:', error.response?.data || error);
      setError('Error saving invoicing details: ' + (error.response?.data?.error || 'Unknown error'));
    }
  };

  const handleChangeActivePaymentMethod = async (paymentMethodId) => {
    try {


      const userResponse = await api.get(`/user?email=${adminEmail}`);
      const user = userResponse.data.user;

      let stripeCustomerId = user.stripeCustomerId;

      console.log("stripeCustomerId" + stripeCustomerId);
      const response = await api.post('/update-active-payment-method', {
        stripeCustomerId,
        paymentMethodId,
      });
  
      if (response.data.success) {
        setActivePaymentMethodId(paymentMethodId);
        setMessage('Payment method updated successfully!');
      }
    } catch (error) {
      console.error('Error changing active payment method:', error);
      setError('Error changing active payment method: ' + (error.response?.data?.error || 'Unknown error'));
    }
  };
  

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {

      const userResponse = await api.get(`/user?email=${adminEmail}`);
      const user = userResponse.data.user;

      let stripeCustomerId = user.stripeCustomerId;
      
      const response = await api.post('/delete-payment-method', {
        stripeCustomerId,
        paymentMethodId,
      });

      if (response.data.success) {
        setPaymentMethods(prevMethods => prevMethods.filter(method => method.id !== paymentMethodId));
        setMessage('Payment method deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting payment method:', error);
      setError('Error deleting payment method: ' + (error.response?.data?.error || 'Unknown error'));
    }
  };

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="col-md-12">
            <div className='activities-widget'>
              <h2>{t('subscription.billing')}</h2>
              {/* Tabs */}
              <div className="tabs">
                {!activeSubscriptions.length > 0 && (
                  <button className={activeTab === 'choosePlan' ? 'tab active' : 'tab'} onClick={() => setActiveTab('choosePlan')}>
                    {t('subscription.choosePlan')}
                  </button>
                )}
                <button className={activeTab === 'activeSubscriptions' ? 'tab active' : 'tab'} onClick={() => setActiveTab('activeSubscriptions')}>
                  {t('subscription.subscription')}
                </button>
                <button className={activeTab === 'invoices' ? 'tab active' : 'tab'} onClick={() => setActiveTab('invoices')}>
                  {t('subscription.invoices')}
                </button>
                <button className={activeTab === 'settings' ? 'tab active' : 'tab'} onClick={() => setActiveTab('settings')}>
                  {t('subscription.settings')}
                </button>
                <hr className='tabs-hr'></hr>
              </div>
  
              {/* Tab Content */}
              {activeTab === 'choosePlan' && !activeSubscriptions.length > 0 && (
                <Elements stripe={stripePromise}>
                  <h3>{t('subscription.choosePlan')}</h3>
                  <CheckoutForm
                    email={adminEmail}
                    onSuccess={handleSubscriptionSuccess}
                    showBasicPlans={showBasicPlans}
                    activeSubscriptions={activeSubscriptions}
                  />
                </Elements>
              )}
  
              {activeTab === 'activeSubscriptions' && (
                <div>
                  <h3>{t('subscription.subscription')}</h3>
                  {activeSubscriptions.length > 0 ? (
                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{t('subscription.plan')}</th>
                            <th>{t('subscription.quantity')}</th>
                            <th>{t('subscription.status')}</th>
                            <th>{t('subscription.action')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeSubscriptions.map((subscription) => (
                            <tr key={subscription.id}>
                              <td>{subscription.planName}</td>
                              <td>{subscription.quantity}</td>
                              <td>
                                <div
                                  className={`status-label-container ${
                                    subscription.status === 'active' ? 'status-label-active' : 'status-label-inactive'
                                  }`}
                                >
                                  {capitalizeFirstLetter(subscription.status === 'canceled' ? t('subscription.cancelled') : subscription.status)}
                                </div>
                              </td>
                              <td>
                                {subscription.cancelAtPeriodEnd ? (
                                  <button onClick={() => handleContinuePlan()} className="btn btn-primary">
                                    {t('subscription.continuePlan')}
                                  </button>
                                ) : (
                                  <button onClick={() => handleOpenCustomerPortalSubscription(subscription.id)} className="btn btn-primary">
                                    {t('subscription.updateSubscription')}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p>{t('subscription.noActiveSubscriptions')}</p>
                  )}
                </div>
              )}
  
              {activeTab === 'invoices' && (
                <div>
                  <h3>{t('subscription.invoices')}</h3>
                  {invoiceData.length > 0 ? (
                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{t('subscription.invoiceId')}</th>
                            <th>{t('subscription.total')}</th>
                            <th>{t('subscription.status')}</th>
                            <th>{t('subscription.invoiceDate')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceData.map((invoice) => (
                            <tr key={invoice.id}>
                              <td><a href={invoice.invoicePdf} target="_blank" rel="noopener noreferrer">{invoice.number}</a></td>
                              <td>{currencyCodeToSymbol(invoice.currency)}{invoice.amount.toFixed(2)}</td>
                              <td>
                                <div
                                  className={`status-label-container ${
                                    invoice.status === 'paid' ? 'status-label-active' : 'status-label-inactive'
                                  }`}
                                >
                                  {capitalizeFirstLetter(invoice.status)}
                                </div>
                              </td>
                              <td>{invoice.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p>{t('subscription.noInvoicesFound')}</p>
                  )}
                </div>
              )}
  
              {activeTab === 'settings' && (
                <div>
                  <div className='widget-settings'>
                    <h3>{t('subscription.paymentMethods')}</h3>
                    <div className='payment-methods'>
                      <ul>
                        {paymentMethods.map(method => (
                          <li key={method.id}>
                            {method.type === 'card' ? (
                              <>
                                {method.card.brand} {t('subscription.endingIn')} {method.card.last4} ({t('subscription.expires')} {method.card.exp_month}/{method.card.exp_year})
                              </>
                            ) : method.type === 'sepa_debit' ? (
                              <>
                                SEPA {t('subscription.endingIn')} {method.sepa_debit.last4} ({t('subscription.bank')}: {method.sepa_debit.bank_code})
                              </>
                            ) : (
                              <span>{t('subscription.unsupportedPaymentMethod')}</span>
                            )}
                            {method.id === activePaymentMethodId ? (
                              <div className='status-label-container status-label-active'>{t('subscription.active')}</div>
                            ) : (
                              <button onClick={() => handleChangeActivePaymentMethod(method.id)} className="btn btn-primary">
                                {t('subscription.setActive')}
                              </button>
                            )}
                            {method.id !== activePaymentMethodId && (
                              <button onClick={() => handleDeletePaymentMethod(method.id)} className="btn btn-danger">
                                {t('subscription.delete')}
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                      <button onClick={() => handleOpenCustomerPortal()} className="btn btn-primary AddPaymentMethod">
                        {t('subscription.addPaymentMethod')}
                      </button>
                    </div>
                  </div>
  
                  <hr className='pageSectionDivider'/>
  
                  <h3>{t('subscription.billingDetails')}</h3>
                  {error && <div className="error-message">{error}</div>}
                  <div className="invoicing-form">
                    <div className="form-group">
                      <label htmlFor="companyName">{t('subscription.companyName')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={invoicingDetails.companyName || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="addressLine1">{t('subscription.address')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="addressLine1"
                        name="addressLine1"
                        value={invoicingDetails.addressLine1 || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="addressLine2">{t('subscription.addressLine2')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="addressLine2"
                        name="addressLine2"
                        value={invoicingDetails.addressLine2 || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">{t('subscription.city')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="city"
                        name="city"
                        value={invoicingDetails.city || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="postalCode">{t('subscription.postalCode')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        value={invoicingDetails.postalCode || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="state">{t('subscription.state')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="state"
                        name="state"
                        value={invoicingDetails.state || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="country">{t('subscription.country')}</label>
                      <select
                        className='form-control'
                        id="country"
                        name="country"
                        value={invoicingDetails.country || ''}
                        onChange={handleInvoicingChange}
                      >
                        <option value="">{t('subscription.selectCountry')}</option>
                        {countries.map((country) => (
                          <option key={country.code} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="vatNumber">{t('subscription.vatNumber')}</label>
                      <input
                        className='form-control'
                        type="text"
                        id="vatNumber"
                        name="vatNumber"
                        value={invoicingDetails.vatNumber || ''}
                        onChange={handleInvoicingChange}
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary" onClick={handleSaveInvoicingDetails}>{t('subscription.saveDetails')}</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default SubscriptionComponent;
