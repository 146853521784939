import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import api from './../api/axios';
import './OnboardingWidget.css';
import Userfront from '@userfront/toolkit/react';
import { useTranslation } from 'react-i18next'; 
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import startIcon from './../assets/start-icon.png'; // Adjust the path as necessary


const OnboardingWidget = () => {
  const [isStep1Complete, setIsStep1Complete] = useState(false);
  const [isStep2Complete, setIsStep2Complete] = useState(false);
  const [isStep3Complete, setIsStep3Complete] = useState(false);
  const [isStep4Complete, setIsStep4Complete] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  const currentUser = Userfront.user.email;
  const { t } = useTranslation(); 

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const settingsResponse = await api.get(`/user?email=${currentUser}`);
        const proxyServer = settingsResponse.data.user.proxyServer;
        const li_at = settingsResponse.data.user.li_at;
        const country = settingsResponse.data.user.country;

        if (proxyServer && country) setIsStep1Complete(true);
        if (li_at) setIsStep2Complete(true);

        const leadsResponse = await api.get('/leads', { params: { user: currentUser } });
        if (leadsResponse.data.length > 0) setIsStep3Complete(true);

        const campaignsResponse = await api.get(`/campaigns?email=${currentUser}`);
        const campaigns = campaignsResponse.data;
        const hasActiveCampaign = campaigns.some(campaign => campaign.status !== 'Concept');
        if (hasActiveCampaign) setIsStep4Complete(false);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const renderStep = (stepNumber, description, isComplete) => (
    <div className="onboarding-step" onClick={() => setCurrentStep(stepNumber)}>
      <FontAwesomeIcon 
        icon={faCheckCircle} 
        className="step-icon" 
        style={{ color: isComplete ? 'green' : 'gray' }} 
      />
      <span className="step-description">{`${stepNumber}. ${description}`}</span>
    </div>
  );

  const getScribehowEmbed = (step) => {
    switch (step) {
      case 1:
        return "https://scribehow.com/embed/Change_Location_Settings__PujSc3KqSH6vhoLTAVdp-w?as=video";
      case 2:
        return "https://scribehow.com/embed/Change_Location_Settings__PujSc3KqSH6vhoLTAVdp-w?as=video";
      case 3:
        return "https://scribehow.com/embed/Change_Location_Settings__PujSc3KqSH6vhoLTAVdp-w?as=video";
      case 4:
        return "https://scribehow.com/embed/Change_Location_Settings__PujSc3KqSH6vhoLTAVdp-w?as=video";
      default:
        return null;
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isStep4Complete) return null;

  let actionLink;
  if (!isStep1Complete) {
    actionLink = <Link to="/settings?tab=location" className="btn btn-primary">{t('home.startOnboarding')}</Link>;
  } else if (isStep1Complete && !isStep2Complete) {
    actionLink = <Link to="/settings?tab=saveCookie" className="btn btn-primary">{t('home.continueOnboarding')}</Link>;
  } else if (isStep1Complete && isStep2Complete && !isStep3Complete) {
    actionLink = <Link to="/leads" className="btn btn-primary">{t('home.continueOnboarding')}</Link>;
  } else {
    actionLink = <Link to="/campaigns" className="btn btn-primary">{t('home.continueOnboarding')}</Link>;
  }

  return (
    <div className="">
      <div className="onboarding-steps-container">
        <div className="chart-header header-container header-container-home">
          <h2>{t('home.gettingStarted')}</h2>
          {actionLink}
        </div>
        
        <p className="welcome-message">{t('home.welcomeMessage')}</p>
  
        {renderStep(1, t('home.completeSettings'), isStep1Complete)}
        {renderStep(2, t('home.connectLinkedInAccount'), isStep2Complete)}
        {renderStep(3, t('home.importLeadList'), isStep3Complete)}
        {renderStep(4, t('home.activateCampaign'), isStep4Complete)}
        
        <div className="onboarding-links">
            <br></br>
          <span>Heb je hulp nodig?  </span>
          <a className="tutorial-link" onClick={openModal}>{t('home.tutorialLink')}</a>
        </div>
      </div>

    

      {/* Modal for Tutorial */}
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('home.tutorialTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getScribehowEmbed(currentStep) && (
            <iframe src={getScribehowEmbed(currentStep)} width="100%" height="640" allowFullScreen frameBorder="0"></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnboardingWidget;
