import React, { useState, useEffect } from 'react';
import Userfront from '@userfront/toolkit/react';
import './Team.css';
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import api from './../../api/axios';
import { FaEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from './../../i18n'; // Import your i18n configuration

const Team = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteData, setInviteData] = useState({ name: '', email: '' });
  const [availableSeats, setAvailableSeats] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchOrganizationUsers();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      updateAvailableSeats();
    }
  }, [users]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchOrganizationUsers = async () => {
    try {
      const email = Userfront.user.email;
      const response = await api.get(`/organization-users`, { params: { email } });
      const users = response.data.users;
      setUsers(users);

      const currentUser = Userfront.user;
      const isAdminUser = users.some(user => String(user.email) === String(currentUser.email) && user.role === 'Admin');
      setIsAdmin(isAdminUser);

      // Fetch user settings including language preference
      const userResponse = await api.get(`/user?email=${currentUser.email}`);
      const userSettings = userResponse.data.user;

      // Set the language based on the user setting
      if (userSettings.language) {
        i18n.changeLanguage(userSettings.language);
      } else {
        i18n.changeLanguage('en'); // Fallback to English if no preference
      }

    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
  };

  const updateAvailableSeats = async () => {
    try {
      const email = Userfront.user.email;
      const subscriptionsResponse = await api.get(`/subscriptions?email=${email}`);
      const activeSubscriptions = subscriptionsResponse.data.subscriptions;

      const totalSeatsBought = activeSubscriptions.reduce((total, sub) => total + sub.quantity, 0);
      const seatsInUse = users.filter(user => user.subscriptionStatus === 'Active').length;
      const available = Math.max(0, totalSeatsBought - seatsInUse);
      setAvailableSeats(available);
    } catch (error) {
      console.error('Error calculating available seats:', error);
      setAvailableSeats(0);
    }
  };

  const handleInvite = async () => {
    setLoading(true);
    setMessage(null);
    try {
      const currentUserEmail = Userfront.user.email;
      const userResponse = await api.get(`/user?email=${currentUserEmail}`);
      const currentUser = userResponse.data.user;

      await api.post('/invite-user', {
        email: inviteData.email,
        name: inviteData.name,
        role: 'Member',
        organizationId: currentUser.organizationId,
      });

      setShowInviteModal(false);
      setMessage({ type: 'success', text: t('user.invitedSuccessfully') });
    } catch (error) {
      console.error('Error inviting user:', error);
      setMessage({ type: 'danger', text: t('user.invitationFailed') });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmStatus = async () => {
    if (!selectedUser) {
      setMessage({ type: 'danger', text: t('user.noUserSelected') });
      return;
    }

    if (availableSeats <= 0 && selectedStatus === 'Active') {
      setMessage({ type: 'danger', text: t('user.noSeatsLeft') });
      return;
    }

    setLoading(true);
    setMessage(null);
    try {
      await api.put('/update-subscription-status', {
        email: selectedUser,
        subscriptionStatus: selectedStatus,
      });

      setMessage({ type: 'success', text: t('user.subscriptionStatusUpdated') });
      setSelectedUser(null);
      setSelectedStatus('');
      await fetchOrganizationUsers();
    } catch (error) {
      console.error('Error updating subscription status:', error);
      setMessage({ type: 'danger', text: t('user.updateFailed') });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setInviteData({
      ...inviteData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectUser = (email, currentStatus) => {
    setSelectedUser(email);
    setSelectedStatus(currentStatus === 'Active' ? 'Inactive' : 'Active');
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleCloseAlert = () => {
    setMessage(null);
  };

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row">
          <div className="col-md-12">
            <div className='activities-widget'>
              {message && (
                <Alert variant={message.type} dismissible onClose={handleCloseAlert}>
                  {message.text}
                </Alert>
              )}

              {loading && (
                <div className="loading-spinner">
                  <Spinner animation="border" />
                </div>
              )}

              <div className="header-container">
                <h2>{t('team.title')}</h2>

                {isAdmin && availableSeats !== null && (
                  <div className='header-info'>
                    <div className="available-seats-container">
                      <span className="available-seats-label">{t('team.availableSeats')}:</span>
                      <span className={`available-seats-text ${availableSeats === 0 ? 'text-danger' : 'text-success'}`}>
                        {availableSeats}
                      </span>
                    </div>
                    {availableSeats > 0 && (
                      <button onClick={() => setShowInviteModal(true)} className="btn btn-primary">
                        {t('team.inviteUser')}
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>{t('team.name')}</th>
                      <th>{t('team.email')}</th>
                      <th>{t('team.role')}</th>
                      <th>{t('team.status')}</th>
                      {isAdmin && <th>{t('team.actions')}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.userId}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>
                          <div className={`status-label-container ${user.subscriptionStatus === 'Active' ? 'status-label-active' : 'status-label-inactive'}`}>
                            {user.subscriptionStatus}
                          </div>
                        </td>
                        {isAdmin && (
                          <td>
                            {selectedUser === user.email ? (
                              <>
                                <Form.Select
                                  value={selectedStatus}
                                  onChange={handleStatusChange}
                                  style={{ marginBottom: '10px' }}
                                >
                                  <option value="Active">{t('team.active')}</option>
                                  <option value="Inactive">{t('team.inactive')}</option>
                                </Form.Select>
                                <Button
                                  onClick={handleConfirmStatus}
                                  className="btn btn-primary"
                                  disabled={availableSeats <= 0 && selectedStatus === 'Active'}
                                >
                                  {t('team.confirm')}
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={() => handleSelectUser(user.email, user.subscriptionStatus)}
                                variant="link"
                              >
                                <FaEdit />
                              </Button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>{t('team.inviteUser')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="inviteName">
                    <Form.Label>{t('team.name')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={inviteData.name}
                      onChange={handleChange}
                      placeholder={t('team.enterName')}
                    />
                  </Form.Group>
                  <Form.Group controlId="inviteEmail">
                    <Form.Label>{t('team.email')}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={inviteData.email}
                      onChange={handleChange}
                      placeholder={t('team.enterEmail')}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowInviteModal(false)}>
                  {t('team.cancel')}
                </Button>
                <Button variant="primary" onClick={handleInvite} disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : t('team.sendInvite')}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
